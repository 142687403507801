<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="stationPage" :fields="fields" :search-model="searchModel"
                :show-selection="true" :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="" prop="type">
          <el-select v-model="searchModel.type" placeholder="用户类型">
            <el-option :label="type" :value="key" v-for="(type, key) in typeOptions" :key="type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="webFarmerSearch">
          <el-input v-model="searchModel.webFarmerSearch" clearable placeholder="用户姓名 手机"></el-input>
        </el-form-item>
        <el-form-item label="" prop="webCreateUserSearch" style="width: 150px">
          <el-input v-model="searchModel.webCreateUserSearch" placeholder="业务人员 手机 姓名" clearable
                    style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item label="" prop="webUserSearch" style="width: 150px">
          <el-input v-model="searchModel.webUserSearch" clearable placeholder="踏勘人员 手机 姓名"
                    style="width: 150px"></el-input>
        </el-form-item>
      </template>
      <template slot="page-actions">
        <el-button @click="handleExport" size="mini" type="primary">批量导出</el-button>
      </template>
    </page-table>
    <el-dialog title="建站详情" :visible.sync="page_dialog_visible" append-to-body
               :close-on-click-modal="false" width="60%" custom-class="min-w-750">
      <div class="descriptions" v-if="current" v-loading="page_form_loading">
        <div class="descriptions-view">
          <table>
            <tbody>
            <tr class="descriptions-row">
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">工单编号</span>
                  <span class="descriptions-item-content">{{ current.id }}</span>
                </div>
              </td>
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">工单状态</span>
                  <span class="descriptions-item-content">{{ stateMap[current.state] }}</span>
                </div>
              </td>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">工单类型</span>
                  <span class="descriptions-item-content">踏勘工单</span>
                </div>
              </td>
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">农户姓名</span>
                  <span class="descriptions-item-content">{{ current.farmer ? current.farmer.name : '' }}</span>
                </div>
              </td>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">农户电话</span>
                  <span class="descriptions-item-content">{{ current.farmer ? current.farmer.phone : '' }}</span>
                </div>
              </td>
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">农户地址</span>
                  <span class="descriptions-item-content">
                    {{
                      current.farmer ? current.farmer.province + current.farmer.city + current.farmer.area + current.farmer.address : ''
                    }}
                  </span>
                </div>
              </td>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item" colspan="1" v-if="current.roofTypeModel">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">屋顶类型</span>
                  <span class="descriptions-item-content">{{ current.roofTypeModel.name || '-' }}</span>
                </div>
              </td>
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">朝向角度</span>
                  <span class="descriptions-item-content">{{ current.angle }}</span>
                </div>
              </td>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">并网距离</span>
                  <span class="descriptions-item-content">{{ current.distance ? current.distance + 'M' : '' }}</span>
                </div>
              </td>
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">预估组件</span>
                  <span
                    class="descriptions-item-content">{{
                      current.estimateSize ? current.estimateSize + '块' : ''
                    }}</span>
                </div>
              </td>
            </tr>
            <!------------------------------------------------->
            <tr class="descriptions-row" v-if="current.type == 1">
              <td class="descriptions-item" colspan="2">
                <div class="descriptions-item-container">
                  <div class="descriptions-item-label">屋顶照片</div>
                  <div class="descriptions-item-content" style="display: flex;align-items: center;flex-wrap: wrap">
                    <div style="width: 20%;margin:0 10px;">
                      <el-image style="width: 100px; height: 100px"
                                v-if="current.roofImg && current.roofImg != ''"
                                :preview-src-list="preViewList(current.roofImg)"
                                :src="current.roofImg | getSourceUri" :fit="'contain'"></el-image>
                      <div class="no-img" v-else>无图片</div>
                      <div>{{current.roofTypeModel.type== 4 ? '屋顶照片' : '楼面屋顶照片'}}</div>
                    </div>
                    <div style="width: 20%;margin:0 10px;">
                      <el-image v-if="current.wallImg != ''" style="width: 100px; height: 100px"
                                :preview-src-list="preViewList(current.wallImg)"
                                :src="current.wallImg | getSourceUri" :fit="'contain'"></el-image>
                      <div v-else class="no-img">无图片</div>
                      <div>{{current.roofTypeModel.type== 4 ? '采光带照片' : '围墙围挡'}}</div>
                    </div>
                    <div style="width: 20%;margin:0 10px;" >
                      <el-image style="width: 100px; height: 100px"
                                v-if="current.stairsImg && current.stairsImg != ''"
                                :preview-src-list="preViewList(current.stairsImg)"
                                :src="current.stairsImg | getSourceUri"
                                :fit="'contain'"></el-image>
                      <div v-else class="no-img">无图片</div>
                      <div>{{current.roofTypeModel.type== 4 ? '气楼照片' : '楼梯及遮挡'}}</div>
                    </div>
                    <div style="width: 20%;margin:0 10px;">
                      <el-image style="width: 100px; height: 100px"
                                v-if="current.floorImg&&current.floorImg == ''"
                                :preview-src-list="preViewList(current.floorImg)"
                                :src="current.floorImg | getSourceUri" :fit="'contain'"></el-image>
                      <div v-else class="no-img">无图片</div>
                      <div>{{current.roofTypeModel.type== 4 ? '瓦型照片' : '楼层朝向'}}</div>
                    </div>
                    <div style="width: 20%;margin:0 10px;">
                      <el-image style="width: 100px; height: 100px"
                                v-if="current.transformerImg && current.transformerImg != ''"
                                :preview-src-list="preViewList(current.transformerImg)"
                                :src="current.transformerImg | getSourceUri" :fit="'contain'"></el-image>
                      <div v-else class="no-img">无图片</div>
                      <div>变压器容量</div>
                    </div>
                    <div style="width: 20%;margin:0 10px;" >
                      <el-image style="width: 100px; height: 100px"
                                v-if="current.distributionImg && current.distributionImg != ''"
                                :preview-src-list="preViewList(current.distributionImg)"
                                :src="current.distributionImg | getSourceUri" :fit="'contain'"></el-image>
                      <div v-else class="no-img">无图片</div>
                      <div>配电房</div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="descriptions-row" v-if="current.type == 0">
              <td class="descriptions-item" colspan="2">
                <div class="descriptions-item-container">
                  <div class="descriptions-item-label">屋顶照片</div>
                  <div class="descriptions-item-content" style="display: flex;align-items: center;flex-wrap: wrap">
                    <div style="width: 20%;margin:0 10px;">
                      <el-image style="width: 100px; height: 100px"
                                v-if="current.inImg && current.inImg != ''"
                                :preview-src-list="preViewList(current.inImg)"
                                :src="current.inImg | getSourceUri" :fit="'contain'"></el-image>
                      <div v-else class="no-img">无图片</div>
                      <div>内部屋顶</div>
                    </div>
                    <div style="width: 20%;margin:0 10px;">
                      <el-image style="width: 100px; height: 100px"
                                v-if="current.planeImg && current.planeImg != ''"
                                :preview-src-list="preViewList(current.planeImg)"
                                :src="current.planeImg | getSourceUri" :fit="'contain'"></el-image>
                      <div v-else class="no-img">无图片</div>
                      <div>屋顶平面</div>
                    </div>
                    <div style="width: 20%;margin:0 10px;">
                      <el-image style="width: 100px; height: 100px"
                                v-if="current.allImg && current.allImg != ''"
                                :preview-src-list="preViewList(current.allImg)"
                                :src="current.allImg | getSourceUri"
                                :fit="'contain'"></el-image>
                      <div v-else class="no-img">无图片</div>
                      <div>房屋整体</div>
                    </div>
                    <div style="width: 20%;margin:0 10px;" >
                      <el-image style="width: 100px; height: 100px"
                                v-if="current.meterImg && current.meterImg != ''"
                                :preview-src-list="preViewList(current.meterImg)"
                                :src="current.meterImg | getSourceUri" :fit="'contain'"></el-image>
                      <div v-else class="no-img">无图片</div>
                      <div>电表箱</div>
                    </div>
                    <div style="width: 20%;margin:0 10px;">
                      <el-image style="width: 100px; height: 100px"
                                v-if="current.stairsImg && current.stairsImg"
                                :preview-src-list="preViewList(current.stairsImg)"
                                :src="current.stairsImg | getSourceUri" :fit="'contain'"></el-image>
                      <div v-else class="no-img">无图片</div>
                      <div>房屋侧面</div>
                    </div>
                    <div style="width: 20%;margin:0 10px;"
                         v-for="(other, index1) in otherImgs" :key="index1">
                      <el-image style="width: 100px; height: 100px"
                                :preview-src-list="preViewList(other.image)"
                                :src="other.image | getSourceUri" :fit="'contain'"></el-image>
                      <div>{{other.name}}</div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item" colspan="2">
                <div class="descriptions-item-container">
                  <div class="descriptions-item-label">勘测图片</div>
                  <div class="descriptions-item-content">
                    <el-image style="width: 100px; height: 100px"
                              v-if="current.surveyImg && current.surveyImg != ''"
                              :preview-src-list="preViewList(current.surveyImg)"
                              :src="current.surveyImg | getSourceUri" :fit="'contain'"></el-image>
                    <div v-else class="no-img">无图片</div>
                  </div>
                </div>
              </td>
            </tr>
            <!------------------------------------------------->
            <tr class="descriptions-row">
              <td class="descriptions-item" colspan="2">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">经销商</span>
                  <span
                    class="descriptions-item-content">{{
                      current.user && current.user.distributor ? current.user.distributor.name : ''
                    }}</span>
                </div>
              </td>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">踏勘人员姓名</span>
                  <span
                    class="descriptions-item-content">{{ current.user ? current.user.name : '' }}</span>
                </div>
              </td>
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">踏勘人员手机</span>
                  <span
                    class="descriptions-item-content">{{ current.user ? current.user.phone : '' }}</span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="dialog-footer">
        <template v-if="current && current.waitConfirm">
          <el-input v-model="current_msg" placeholder="备注 不通过填写"
                    style="display: inline-block;width: 300px;margin-right: 20px;"></el-input>
        </template>
        <el-button @click="page_dialog_visible = false">关 闭</el-button>
        <el-button @click="audit('FAIL')" type="danger">不通过</el-button>
        <el-button @click="audit('SUCCESS')" type="primary">通 过</el-button>
      </div>
    </el-dialog>
    <el-dialog title="验收详情" :visible.sync="page_yanshou_visible" append-to-body
               :close-on-click-modal="false" width="35%" custom-class="min-w-750">
      <div class="descriptions" v-if="current" v-loading="page_form_loading">
        <div class="descriptions-view">
          <table>
            <tbody>
            <tr class="descriptions-row">
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">装机容量</span>
                  <span class="descriptions-item-content">{{ current.capacity }}</span>
                </div>
              </td>
              <td class="descriptions-item" colspan="1" v-if="current.roofTypeModel">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">屋顶类型</span>
                  <span class="descriptions-item-content">{{ current.roofTypeModel.name || '-' }}</span>
                </div>
              </td>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">组件块数</span>
                  <span class="descriptions-item-content">{{current.size}}</span>
                </div>
              </td>
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">规格</span>
                  <span class="descriptions-item-content">{{ current.norms}}</span>
                </div>
              </td>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">验收日期</span>
                  <span class="descriptions-item-content">{{ current.confirmTime }}</span>
                </div>
              </td>
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">并网日期</span>
                  <span class="descriptions-item-content">
                    {{current.signTime}}
                  </span>
                </div>
              </td>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">发电户号</span>
                  <span class="descriptions-item-content">{{current.generatorNo}}</span>
                </div>
              </td>
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">开发商</span>
                  <span class="descriptions-item-content">{{ current.developers?current.developers.name:'' }}</span>
                </div>
              </td>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item" colspan="1" v-if="current.distributor">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">经销商</span>
                  <span class="descriptions-item-content">{{ current.distributor?current.distributor.name:'' }}</span>
                </div>
              </td>
              <td class="descriptions-item" colspan="1">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">合同类型</span>
                  <span
                    class="descriptions-item-content">{{oserType[current.type]}}</span>
                </div>
              </td>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item" colspan="2">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">安装照片</span>
                  <span class="descriptions-item-content" v-if="current_roof_imgs.length > 0"
                        style="flex-wrap:wrap;">
                    <el-image style="width: 150px; height: 150px;margin-right: 8px;"
                              v-for="(img,index) in current_roof_imgs" :key="index" :src="img"
                              :preview-src-list="current_roof_imgs"></el-image>
                  </span>
                  <span class="descriptions-item-content" v-else>无</span>
                </div>
              </td>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item" colspan="2">
                <div class="descriptions-item-container">
                  <span class="descriptions-item-label">用户确认单</span>
                  <span class="descriptions-item-content">
                    <el-image style="width: 150px; height: 150px;" :src="current_size_imgs[0]"
                              :preview-src-list="current_size_imgs" v-if="current_size_imgs.length > 0"></el-image>
                    <span v-else>无</span>
                  </span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="dialog-footer">
        <template v-if="current && current.waitConfirm">
          <el-input v-model="current_msg" placeholder="备注 不通过填写"
                    style="display: inline-block;width: 300px;margin-right: 20px;"></el-input>
        </template>
        <el-button @click="page_yanshou_visible = false">关 闭</el-button>
        <el-button @click="audit('FAIL')" type="danger">不通过</el-button>
        <el-button @click="audit('SUCCESS')" type="primary">通 过</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import PageHeader from "@/components/PageHeader"
  import PageTable from "@/components/PageTable"
  import {stationPage, stationReview, stationCheck, stationExport} from "@/api/common"
  import helper from "@/utils/helper"
  import downloadBlob from "@/function/downloadBlob"

  const stateMap = {
    WAIT: '待接单',
    SURVEYED: '待踏勘',
    EXAMINE: '审核中',
    SUCCESS: '审核成功',
    FAIL: '审核失败',
    CLOSE: '闭单',
  };

  const roofTypeMap = {
    '1': '平屋顶',
    '2': '斜屋顶',
    '3': '阳光棚',
  };
  const oserType = {
    "02": "屋顶租赁",
    "03": "全款销售",
    "01": "个人贷款",
  };

  const typeMap = {
    '0': '农户',
    '1': '公共屋顶',
  };


  export default {
    name: "Stations",
    data() {
      return {
        searchModel: {
          type: '',
          webFarmerSearch: '',
          webCreateUserSearch: '',
          webUserSearch: '',
        },
        stationPage: stationPage,
        fields: [
          {label: '工单编号', key: 'id', width: 180},
          {
            label: '工单状态', width: 90,
            render(_, h) {
              return h('div', '审核中')
            }
          },
          {
            label: '工单类型',
            width: 90,
            render(_, h) {
              return h('div', '踏勘工单')
            }
          },
          {
            label: '用户类型', width: 90,
            render({type}, h) {
              if (String(type) === '0' || String(type) === '1') {
                return h('div', type == '1' ? '公共屋顶' : '农户')
              }
            }
          },
          {
            label: '用户姓名',width: 110,
            render({farmer}, h) {
              return h('div', farmer ? farmer.name : '')
            }
          },
          {
            label: '用户电话', width: 120,
            render({farmer}, h) {
              return h('div', farmer ? farmer.phone : '')
            }
          },
          {
            label: "用户地址",width: 170,
            render({farmer}, h) {
              let province = farmer ? farmer.province : ''
              let city = farmer ? farmer.city : ''
              let area = farmer ? farmer.area : ''
              let address = farmer ? farmer.address : ''
              return h('div', province + city + area + address)
            }
          },
          {
            label: "屋顶类型", width: 90,
            render({roofTypeModel}, h) {
              if (roofTypeModel) {
                return h('div', roofTypeModel.name || '')
              }
            }
          },
          {
            label: "并网距离", width: 100,
            render({distance}, h) {
              return h('div', distance ? distance + 'M' : '')
            }
          },
          {
            label: "预估组件", width: 100,
            render({estimateSize}, h) {
              return h('div', estimateSize ? estimateSize + '块' : '')
            }
          },
          {
            label: "经销商名称", width: 160,
            render({user}, h) {
              return h('div', user && user.distributor ? user.distributor.name : '')
            }
          },
          {
            label: "踏勘人员", width: 100,
            render({user}, h) {
              return h('div', user ? user.name : '')
            }
          },
          {
            label: "手机号", width: 120,
            render({user}, h) {
              return h('div', user ? user.phone : '')
            }
          },
          {label: '踏勘时间', key: 'time', width: 180, align: 'center'},
        ],
        actions: [
          {
            action: 'info',
            label: '验收审核',
            type: 'primary',
            showAction(item) {
              return item.waitConfirm
            }
          },
          {
            action: 'info',
            label: '建站审核',
            type: 'primary',
            showAction(item) {
              return !item.waitConfirm
            }
          },
          {
            action: 'info',
            label: '查看详情',
            type: 'primary',
          }

        ],
        stateMap: stateMap,
        roofTypeMap: roofTypeMap,
        oserType: oserType,
        typeOptions: typeMap,
        page_form_loading: false,
        current: null,
        current_roof_imgs: [],
        current_size_imgs: [],
        current_msg: '',
        page_dialog_visible: false,//建站审核
        page_yanshou_visible: false,//验收审核


        otherImgs: [], // 其他图片多张
      }
    },
    components: {
      PageHeader,
      PageTable
    },
    created() {

    },
    mounted() {

    },
    activated() {
      this.$refs.table && this.$refs.table.loadData()
    },
    methods: {
      handleExport() {
        let that = this, ids = []
        let selected = that.$refs.table.selection
        for (let item of selected) {
          ids.push(item.id)
        }
        let data = Object.assign(that.searchModel, {
          exportIds: ids.length ? ids.join(',') : ''
        })
        stationExport(data).then(res => {
          downloadBlob(res)
        }).catch(err => {
          console.log(err)
        })
      },
      handleItemActionClick(action, item) {
        let that = this
        that[action + 'Action'](item)
      },
      infoAction(item) {
        let that = this
        // roofImg 	楼面屋顶照片
        // wallImg 围墙围挡
        // stairsImg 楼梯及遮挡
        // floorImg 楼层朝向
        // transformerImg 变压器容量
        // distributionImg 配电房
        // surveyImg 勘测图表
        if (item.waitConfirm) {//验收审核
          that.current = item.project
          that.current_roof_imgs = item.project.installImg ? helper.getSourceUris(item.project.installImg.split(',')) : []  //安装照片
          that.current_size_imgs = item.project.confirmImg ? helper.getSourceUris(item.project.confirmImg.split(',')) : []
          that.page_yanshou_visible = true;
          that.current.recoredId = item.id;
          that.current.waitConfirm = true;
        } else {//建站审核
          that.current = item
          console.log('当前建站信息', that.current)
          // that.current_roof_imgs = item.roofImg ? helper.getSourceUris(item.roofImg.split(',')) : []
          // that.current_size_imgs = item.roofSize ? helper.getSourceUris(item.roofSize.split(',')) : []

          if(that.current.otherImg && that.current.otherImg != ''){
            if(that.current.otherImg.indexOf('name')){
              that.otherImgs = JSON.parse(that.current.otherImg)
            } else {
              that.otherImgs = [
                {name: '其他', image:  that.current.otherImg}
              ]
            }
          } else {
            that.otherImgs = []
          }
          that.page_dialog_visible = true
        }
      },
      preViewList(url) {
        let link = helper.getSourceUri(url)
        return [link]
      },
      audit(state) {
        let that = this
        if (that.current.waitConfirm && that.current_msg == '' && state == 'FAIL') {
          that.$message.error('请填写备注')
          return
        }
        that.page_form_loading = true;
        if (that.current.waitConfirm) {//验收审核
          stationCheck(that.current.recoredId, state, that.current_msg).then(() => {
            that.$message.success('操作成功')
            that.page_form_loading = false
            that.page_yanshou_visible = false
            that.$refs.table.loadData()
          }).catch(() => {
            that.page_form_loading = false
          })
        } else {//建站审核
          stationReview(that.current.id, state).then(() => {
            that.$message.success('操作成功')
            that.page_form_loading = false
            that.page_dialog_visible = false
            that.$refs.table.loadData()
          }).catch(() => {
            that.page_form_loading = false
          })
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .no-img {
    width: 100px;
    height: 100px;
    border: 1px solid #eee;
    line-height: 100px;
    text-align: center;
  }

</style>
